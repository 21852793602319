.columns {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icons > img {
  width: 2rem;
}

/* Desktop */
@media (min-width: 1440px) {
  .columns {
    max-width: 1440px;
    margin: 0 auto;
  }
}
