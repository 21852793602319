.wrapper {
  position: relative;
  /* text-align: center; */
}

.img {
  width: 100%;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
}
