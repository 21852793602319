/* Mobile */

.mainsection {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picture {
  width: 100%;
  height: 400px;
}

.picture img {
  border-radius: 30px;
}

.graphic {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.header {
  text-align: center;
  font-size: 20px;
  height: 2rem;
  padding: 1rem 2rem;
  align-content: center;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .graphic {
    width: 70%;
  }

  .picture {
    height: 600px;
    border-radius: 30px;
  }
}

/* Big Tablet */
@media only screen and (min-width: 865px) {
  .graphic {
    width: 60%;
  }

  .picture {
    height: 600px;
    border-radius: 30px;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .graphic {
    width: 50%;
  }

  .picture {
    height: 700px;
    border-radius: 30px;
  }
}
