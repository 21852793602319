.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  display: inline-block;
  color: black;
}

a.button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
}
