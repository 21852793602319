.section {
  text-align: left;
  padding: 0.5rem 1rem;
  font-size: 14px;
}

/* Desktop */
@media (min-width: 1440px) {
  .section {
    max-width: 1440px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
}
