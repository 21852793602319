/* Language Selector Styles */
.languageSelector {
  position: relative;
  display: inline-block;
}

.selectorButton {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px;
  outline: none;
}

.selectorButton {
  color: rgba(0, 0, 0, 0.6);
}

.globeIcon {
  margin-right: 8px;
  font-size: 14px;
}

.flagIcon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 8px;
}

.languageName {
  margin-right: 4px;
  text-transform: uppercase;
}

.chevronIcon {
  font-size: 12px;
  margin-left: 4px;
}

/* Dropdown Menu Styles */
.dropdownMenu {
  position: absolute;
  right: 0;
  background-color: #fff;
  color: #333;
  min-width: 160px;
  border: 1px solid #ccc;
  z-index: 1000;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdownItem {
  width: 100%;
}

.languageButton {
  width: 100%;
  background: none;
  border: none;
  padding: 8px 12px;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: inherit;
}

.languageButton:hover {
  background-color: #f8f9fa;
}

.checkIcon {
  margin-left: auto;
  font-size: 14px;
}
