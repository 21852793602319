/* Mobile */
.columns {
  display: grid;
  grid-template-columns: 1fr;
}

.columns > div {
  padding: 0.5rem;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .columns {
    grid-template-columns: 1fr 1fr;
  }
  .columns > div {
    padding: 0;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .columns {
    grid-template-columns: 1fr 1fr;
  }
}
