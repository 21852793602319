.step {
  display: flex;
  align-items: center;
  padding: 20px 40px;
}

.stepNumber {
  width: 30px;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.stepLabel {
  font-size: 18px;
}

.active .stepNumber {
  background-color: blue;
  color: white;
}

.left {
  margin-right: auto;
  text-align: left;
}

.right {
  margin-left: auto;
  text-align: right;
}
