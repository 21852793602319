/* Mobile and Tablet */
.infocard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
}

.infoicon {
  margin-bottom: 15px;
}

.iconimage {
  width: 50px;
  height: auto;
  object-fit: contain;
}

.infocontent h3 {
  margin: 0;
  font-size: 14px;
}

.infocontent p {
  margin: 10px 0 0;
  font-size: 14px;
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .infocard {
    flex-direction: row;
    text-align: left;
  }

  .infoicon {
    margin-right: 20px;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  .iconimage {
    height: auto;
  }
}
