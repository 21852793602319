/* Mobile */
.logo {
  width: 10rem;
}

/* Tablet */
@media only screen and (min-width: 600px) {
}

/* Desktop */
@media (min-width: 1440px) {
}
