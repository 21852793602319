/* Mobile */
.imgmoveout > img {
  order: 1;
}

.section h3 {
  color: #0091ffe1;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .section {
    padding: 1rem;
  }

  .imgmoveout > img {
    order: 2;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .section {
    text-align: left;
  }
}
