/* Header styles */
.header {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
}

.linkscontainer {
  display: flex;
  align-items: center;
}

.logo {
  width: 10rem;
}

/* Mobile styles */
.burger {
  display: block;
  cursor: pointer;
  z-index: 1001;
  position: relative;
  background: none;
  border: none;
  font-size: 1.5rem;
}

.icon {
  color: rgba(0, 0, 0, 0.6);
}

.navLink {
  font-size: 1.5rem;
  padding: 1rem;
}

.navLinks {
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: none;
  justify-content: center;
  align-items: center;
}

.navLinks.active {
  display: flex;
  transform: translateX(0);
  opacity: 1;
  touch-action: none;
}

.navLinksInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
}

/* Tablet styles */
@media only screen and (min-width: 600px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem;
  }

  .logo {
    width: 12rem;
  }

  .burger {
    display: none;
  }

  .navLink {
    font-size: 14px;
  }

  .navLinks {
    display: flex;
    position: relative;
    top: auto;
    height: auto;
    background: none;
    transform: none;
    width: fit-content;
  }

  .navLinksInner {
    flex-direction: row;
    background: none;
  }
}

/* Desktop styles */
@media only screen and (min-width: 1024px) {
  .header {
    padding: 2rem;
  }

  .logo {
    width: 15rem;
  }
}
