/* Mobile */
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section h3 {
  color: #0091ffe1;
}

.mediasection .imageclass {
  display: block;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .mediasection .textbox {
    order: 2;
  }

  .mediasection .imageclass {
    order: 1;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .mediasection .textbox {
    order: 2;
  }

  .mediasection .imageclass {
    order: 1;
  }
}
