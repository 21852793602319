/* Mobile */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.section h1 {
  color: #0091ffe1;
}

.title {
  text-align: center;
  padding: 2rem;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Tablet */
@media only screen and (min-width: 600px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .container {
    padding: 2rem 15%;
  }
}
