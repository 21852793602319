/* Mobile */
.header {
  text-align: center;
  background-color: #f9f9f9;
  padding: 3rem 0;
  align-content: center;
  text-align: center;
}

.pictures {
  grid-template-columns: repeat(1, 1fr);
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .pictures {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .paragraph {
    text-align: center;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .columns {
    display: grid;
  }

  .pictures {
    display: flex;
  }

  .paragraph {
    padding: 3rem 14rem;
    text-align: center;
  }

  .header {
    height: 3rem;
  }
}
