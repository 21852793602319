/* Mobile */
.navlink {
  font-size: 10px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  list-style: none;
  text-transform: uppercase;
  flex-wrap: wrap;
  align-items: center;
  text-align-last: center;
}

.navitem {
  color: grey;
  text-decoration: none;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .navlink {
    font-size: 10px;
  }
  .navitem {
    padding: 1rem;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .navlink {
    font-size: 12px;
  }
}
