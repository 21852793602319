/* Mobile */

.cardcontainer {
  display: flex;
  justify-content: center;
}

.cardcontainer > div {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.imagecard {
  background-color: #f9f9f9;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;
  justify-content: space-evenly;
  padding: 2rem;
  margin: 1rem;
}

.imagecardpicture {
  background-color: #f9f9f9;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;
  justify-content: space-evenly;
  margin: 1rem;
}

.imagecard > div {
  margin-top: 1rem;
}

.imagecard > img {
  border-radius: 100%;
}

.email {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.email:hover {
  color: rgb(120, 120, 120);
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .imagecard {
    height: auto;
    padding: 1rem;
    max-width: 350px;
  }

  .cardcontainer {
    display: flex;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .imagecard {
    width: 100%;
    padding: 2rem;
    margin: 1rem;
  }

  .cardcontainer {
    width: 100%;
  }

  .name {
    font-size: 20px;
  }

  .email {
    font-size: 14px;
  }
}
