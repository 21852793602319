/* Mobile */
.columns {
  display: grid;
  border-bottom: 1px solid rgb(219, 219, 219);
  text-align: center;
  width: 100%;
}

.faqquestion {
  background: white;
  border: 0;
  border-bottom: 1rem;
  font-size: 0.875rem;
}

/* Tablet */
@media only screen and (min-width: 600px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
