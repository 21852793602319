/* Mobile */
.mediasection h3 {
  color: #ff5e00c7;
}

/* Tablet */
@media only screen and (min-width: 600px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
