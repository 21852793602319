.contactcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5e008f;
  height: 300px;
  padding: 0;
}

.bracket {
  width: 50px;
  height: auto;
  margin: 0 10px;
}

.emailLink {
  font-size: 20px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}

.emailLink:hover {
  color: #ddd;
}

.infocontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.phonenumber {
  font-size: 16px;
  margin: 0.5rem;
}

.email {
  font-size: 16px;
  margin: 0.5rem;
}

.email a {
  text-decoration: none;
  color: white;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .contactcontainer {
    height: 500px;
  }

  .bracket {
    width: 50px;
    height: auto;
    margin: 0 10px;
  }

  .emailLink {
    font-size: 30px;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .contactcontainer {
    height: 500px;
  }

  .bracket {
    width: 50px;
  }

  .emailLink {
    font-size: 28px;
    color: white;
  }

  .phonenumber {
    font-size: 24px;
  }

  .email {
    font-size: 24px;
  }
}
