.slideContent {
  text-align: center;
  padding: 2rem 0;
  margin: 0 5rem;
  height: 250px;
}

.slideTitle {
  font-size: 20px;
  margin: 0.5rem 0;
}

.slideDescription {
  font-size: 1rem;
  margin: 1rem 0;
  font-size: 14px;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .slideContent {
    padding: 2rem 3rem;
    height: 200px;
  }

  .slideTitle {
    font-size: 20px;
  }
}

/* Desktop */
@media (min-width: 1440px) {
  .slideContent {
    margin: 0 10rem;
    padding: 3rem 7rem;
  }

  .slideTitle {
    font-size: 20px;
  }
}
