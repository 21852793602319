/* Mobile */
.section {
  padding: 0;
}

.introheader {
  text-align: center;
  font-size: 20px;
  height: 3rem;
  background-color: #f9f9f9;
  padding: 3rem 6rem;
  align-content: center;
  text-align: center;
}

/* Tablet */
@media only screen and (min-width: 600px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
