/* Mobile */
.footer_container {
  display: grid;
  background-color: rgb(6, 6, 6);
  color: #ffffff;
  padding: 1rem;
  font-size: 12px;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .footer_container {
    font-size: 14px;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .footer_container {
  }
}
