.slideshowContainer {
  position: relative;
  margin-top: 3rem;
  padding: 0;
}

.content {
  background-color: #f9f9f9;
}

.logoContainer {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
}

.dotsContainer {
  text-align: center;
}

.mySlides {
  display: block;
}

.logo {
  width: 3rem;
}

.aboutLink {
  padding: 0.5rem 0;
  text-align: center;
}

.aboutLink > a {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  padding: 0.625rem 1rem;
  margin-top: -1.375rem;
  color: white;
  font-weight: bold;
  font-size: 1.125rem;
  transition: background-color 0.6s ease;
  border-radius: 0.1875rem;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.dot {
  cursor: pointer;
  height: 0.9375rem;
  width: 0.9375rem;
  margin: 0 0.125rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Tablet */
@media only screen and (min-width: 600px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
