/* Mobile */
.section {
  padding: 0;
  position: relative;
  overflow: hidden;
}

.overlaywrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.backgroundvideo {
  width: 100%;
  height: 600px;
  display: block;
  object-fit: cover;
}

.overlaycontent {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  justify-content: center;
  flex: 1;
}

.landlordcard {
  background-color: #ff5e006f;
  color: white;
}

.tenantcard {
  background-color: #0091ff6c;
  color: white;
}

.card h2 {
  font-size: 20px;
}

.card p {
  font-size: 16px;
  margin-bottom: 15px;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .overlaycontent {
    width: 100%;
  }

  .backgroundimage {
    height: 1000px;
    display: block;
    object-fit: cover;
  }

  .card {
    margin: 0;
    padding: 8rem;
  }

  .card p {
    font-size: large;
  }

  .card h2 {
    font-size: 24px;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .overlaycontent {
    flex-direction: row;
    width: 100%;
    top: 0;
    left: 0;
  }

  .overlaywrapper {
    width: 100%;
    position: relative;
  }

  .card {
    max-width: 100%;
    padding: 5rem;
  }

  .card h2 {
    font-size: 28px;
  }

  .card p {
    font-size: x-large;
  }

  .backgroundimage {
    height: 100%;
    object-fit: cover;
  }
}
