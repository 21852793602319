/* Mobile */
.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  align-items: center;
  height: 50%;
  gap: 2.5rem;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .navbar {
    align-items: center;
    flex-direction: row;
    gap: 0rem;
  }
}
