.processScroll {
  display: flex;
  justify-content: center;
  position: relative;
}

.stepsContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.line {
  display: none;
}

.step {
  max-width: 600px;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.left {
  margin-right: auto;
}

.right {
  margin-left: auto;
}

.headingtitel {
  text-align: center;
}

@media (min-width: 768px) {
  .step {
    width: 90%;
    margin: 10px auto;
  }

  .line {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #00a7e2;
    z-index: 0;
  }
}
