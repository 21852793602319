/* Mobil */
.columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .columns {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .columns {
    grid-template-columns: repeat(3, 1fr);
  }
}
