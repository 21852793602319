/* Mobile */

.imageclass > img {
  border-radius: 30px;
}

.section {
  width: 100%;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .section {
    padding: 1rem;
    text-align: left;
  }

  .textbox {
    margin-right: 3rem;
  }

  .imageclass {
    margin-left: 3rem;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
