/* Mobile */
.section {
  padding: 0;
}

.content {
  align-content: center;
  text-align: center;
  background-color: #ff5e0080;
}

.landlordintrotext {
  color: white;
}

.landlordparagraph {
  font-size: 20px;
}

.landlordheader {
  font-size: 24px;
  text-transform: uppercase;
}

.img {
  height: 15rem;
}

/* Tablet */
@media only screen and (min-width: 600px) {
  .content {
    padding: 2rem;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .content {
    padding: 3rem 6rem;
  }

  .img {
    height: 25rem;
  }
}
