/* Mobile */
.section h3 {
  color: #0091ffe1;
}

/* Tablet */
@media only screen and (min-width: 600px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
