.stepContainer {
  text-align: center;
  display: flex;
  justify-content: center;
}

.dotContainer {
  display: none;
}

.step {
  width: 80%;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 20px 0;
}

.steplabel {
  font-weight: bold;
  color: #00a7e2;
}

.steptitel {
  text-align: right;
}

.readmore {
  text-align: center;
  margin: 2rem;
}

.readmorebuttonCustom {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
}

@media (min-width: 768px) {
  .stepsList {
    flex-direction: column;
    position: relative;
  }

  .stepContainer {
    display: flex;
    align-items: center;
    position: relative;
  }

  .dotContainer {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .dot {
    width: 1rem;
    height: 1rem;
    background-color: #00a7e2;
    border-radius: 50%;
  }

  .step {
    width: 40%;
    padding: 20px;
    border-radius: 8px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .left {
    margin-right: auto;
    text-align: left;
    justify-content: left;
  }

  .right {
    margin-left: auto;
    justify-content: right;
    text-align: left;
  }

  .readmore {
    text-align: right;
    margin: 1rem 0;
  }
}
